<template>
  <v-container v-if="alerts != null" class="pb-0">
    <v-alert
      v-model="alert.show"
      v-for="(alert, index) in alerts"
      :key="index"
      :type="alert.type"
      :border="alert.border"
      :elevation="alert.elevation"
      :colored-border="alert.coloredBorder"
      :prominent="alert.prominent"
      :dismissible="alert.dismissible"
      :color="alert.color"
      :icon="alert.icon"
      transition="scroll-x-transition"
      class="py-5"
    >
      <v-row v-if="alert.button != null" align="center" justify="end">
        <v-col class="grow" v-html="alert.text.de"></v-col>
        <v-col class="shrink">
          <v-btn
            class="float-right"
            :to="alert.button.url"
            :color="alert.type"
            @click.stop="alert.show = false"
            >{{ alert.button.text.de }}</v-btn
          >
        </v-col>
      </v-row>
      <div v-else v-html="alert.text.de"></div>
    </v-alert>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    alerts: [
      {
        show: false,
        type: "error",
        text: {
          de:
            "Die <strong>nächste Gruppe</strong> findet am <strong>20.10.2023 um 17:15</strong> statt. Der Termin am 13.10.2023 muss leider ausfallen.",
          en: null
        },
        dismissible: true,
        prominent: true,
        elevation: 1,
        coloredBorder: true,
        border: "bottom",
        color: null,
        icon: "mdi-alert-circle-outline"
      },
      {
        show: false,
        type: "info",
        text: {
          de:
            "Essstörungen sind nicht nur für den Betroffenen schwer. Auch das Umfeld sieht sich oft mit Problemen und Auswirkungen der Krankheit konfrontiert. Darüber zu reden ist oft nicht einfach. Wir laden deshalb am 17.08.2021 zu einem Treffen ein, welches sich speziell an Angehörige richtet. Es bietet eine Möglichkeit sich auszutauschen, in Kontakt zu kommen und offen zu sprechen.",
          en: null
        },
        dismissible: true,
        prominent: true,
        elevation: 1,
        coloredBorder: true,
        border: "bottom",
        color: null,
        icon: "mdi-calendar-alert"
      }
    ]
  })
};
</script>
<style scoped>
.grow {
  min-width: 150px;
}
</style>
